import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';

const onError = init(PUBLIC_SENTRY_DSN, {
	sentryOptions: {
		// ... Other Sentry Config
	},
});

export const handleError = onError((e, sentryEventId) => {
	console.error('client error', { error: e, sentryEventId });
	// Your Error Handler
});
